// overrides
.TextField__container {
  &:focus-within {
    border: 1px solid var(--bg-300) !important;
    box-shadow: 0px 0px 0px 2px var(--bg-200);
  }
}

// utility classes
.font-primary {
  color: var(--bg-500);
}
