@import '../../shared';

.Avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-family: 'Inter', sans-serif;

  &--small {
    width: 24px;
    height: 24px;
    font-size: 0.625rem;
  }

  &--medium {
    width: 32px;
    height: 32px;
    font-size: 0.875rem;
  }

  &--large {
    width: 40px;
    height: 40px;
    font-size: 1rem;
  }
}

.AvatarStack {
  display: flex;
  position: relative;
  align-items: center;

  &__container {
    background: $white;
    position: absolute;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &--small {
      width: 28px;
      height: 28px;
    }

    &--medium {
      width: 36px;
      height: 36px;
    }

    &--large {
      width: 45px;
      height: 45px;
    }
  }
}
