@import '../../../colors';

.TextField {
  font-family: 'Inter', sans-serif;

  &__label {
    display: flex;
    justify-content: space-between;
    font-size: 0.875rem;
    margin-bottom: 5px;

    &--primary {
      color: $dark-2;
    }

    &--link {
      color: $base-blue;
      text-decoration: unset;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    border: 1px solid $light-1;
    border-radius: 5px;
    padding: 10px 12px;
    font-size: 0.875rem;

    &--error {
      border: 1px solid $base-red;
    }

    &--disabled {
      border: none;
      background: rgba($light-1, 0.25);
    }

    &:focus-within {
      border: 1px solid rgba($dark-2, 0.5);
    }
  }

  &__input {
    color: $dark-2;
    border: unset;
    flex-grow: 1;
    font-size: 0.875rem;

    &-title {
      color: rgba($dark-2, 0.8);
    }

    &::placeholder {
      color: rgba($dark-2, 0.5);
    }

    &:focus {
      outline: unset;
    }

    &:disabled {
      background: unset;

      &::placeholder {
        color: $dark-2;
      }
    }
  }

  &__error-feedback {
    color: $base-red;
    font-size: 0.75rem;
    margin: 0;
  }
}

.SelectField {
  font-family: 'Inter', sans-serif;

  &__input {
    color: $dark-2;
    border: 1px solid $light-1;
    padding: 10px 12px;
    flex-grow: 1;
    font-size: 0.875rem;
    border-radius: 5px;
    width: 100%;

    option[value=''] {
      display: none;
    }

    &:focus {
      border: 1px solid rgba($dark-2, 0.5);
    }
  }
}

.Field {
  &__label {
    display: flex;
    justify-content: space-between;
    font-size: 0.875rem;
    margin-bottom: 5px;

    &--primary {
      color: $dark-2;
    }

    &--link {
      color: $base-blue;
      text-decoration: unset;
    }
  }
}
