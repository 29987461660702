@import 'variables';

.Alert {
  display: flex;
  align-items: flex-start;
  padding: 0.75rem 1rem;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  border-radius: 16px;

  &--success {
    background-color: $success-light-green;
    border: 1px solid $success-green;
  }

  &--error {
    background-color: $error-bg-red;
    border: 1px solid $error-red;
  }

  &__text {
    margin-left: 1rem;

    &--success {
      color: $success-default-green;
    }

    &--error {
      color: $text-error-red;
    }
  }
}
