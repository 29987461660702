@import 'variables';

.BusinessLogo {
  .Avatar {
    font-weight: 600;
  }

  &__name {
    margin-left: 0.5em;
    font-weight: 600;
  }

  &__img {
    border-radius: 50%;
  }

  &--solid {
    .Avatar {
      background-color: var(--bg-400);
      color: $white;
    }

    .BusinessLogo__name {
      color: $dark-5;
    }
  }

  &--light {
    .Avatar {
      background-color: $white;
      color: var(--bg-400);
    }

    .BusinessLogo__name {
      color: $white;
    }
  }
}
