@import './variables';
@import 'defaults';

* {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Inter', 'Roboto', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button-global {
  border-radius: 1px;
}

.CustomizedInput {
  &__field {
    position: relative;

    &-info {
      display: block;
      color: $dark-5;
      margin-bottom: 10px;

      @media screen and (max-width: $breakpoint-tablet) {
        font-size: $font-11;
      }
    }

    &-error {
      border: 1px solid $error-red;
    }

    &-feedback {
      color: $error-red;
      font-size: 0.75rem;
    }

    &-description {
      opacity: 0;
      position: absolute;
      right: -300px;
      width: 250px;
      top: 25px;

      @media screen and (max-width: $breakpoint-desktop) {
        display: none;
      }
    }
  }
}
