@import '../../shared';

.Badge {
  font-size: 0.6875rem;
  padding: 2px 8px;
  cursor: pointer;
  text-transform: uppercase;
  margin: 0;
  font-family: 'Inter', sans-serif;
  text-align: center;
  letter-spacing: 0.3px;
  width: fit-content;
  font-weight: 700;

  &--pill {
    border-radius: 8px;
  }

  &--badge {
    border-radius: 2px;
  }
}
