@import 'variables';

.ErrorPage {
  height: 100vh;
  background-color: var(--bg-400, black);

  &__container {
    width: 80%;
    margin: auto;
    padding: 2em 0;
    overflow: hidden;

    @media screen and (max-width: $breakpoint-tablet) {
      width: 100%;

      .BusinessLogo {
        margin-left: 1em;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2em 0;
    padding: 0 1em;
  }

  &__title {
    margin: 1em 0 0;
    color: $white;
    text-align: center;
  }

  .Text {
    color: $white;
    margin: 1em 0;
    width: 500px;
    max-width: 100%;
    text-align: center;
  }

  &__cta {
    background-color: var(--bg-100, $white);
    color: var(--bg-500, black);
    border-radius: 10px;
    padding: 1em;
    font-size: $font-14;
    text-decoration: none;
    width: fit-content;
  }
}
