@import '../../colors';

.Alert {
  min-width: 350px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid $neutral-3;
  display: flex;
  font-family: 'Inter', sans-serif;

  &__icon {
    margin-right: 10px;
    margin-top: -2px;
  }

  &__title {
    margin: 0 0 10px;
    font-size: 0.75rem;
    color: $dark-3;
    font-weight: 600;
  }

  &__detail {
    font-size: 0.75rem;
    color: $dark-2;
    font-weight: 400;
    margin: 0;
  }
}
