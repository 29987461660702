@import 'variables';

.NavBar {
  &__mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__mobile-logo {
    display: flex;
    align-items: center;

    > svg {
      cursor: pointer;
      margin-right: 0.5rem;
    }
  }

  &__logo {
    .Avatar {
      background-color: var(--bg-400);
      color: $white;
    }

    > p {
      color: $grey-2;
    }
  }

  &__main {
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 13;
    left: -292px;
    width: 202px;
    padding: 1em;
    position: fixed;
    background-color: $white;
    transition: all 0.5s ease-in-out;

    &-open {
      left: 0;
      width: calc(100% - 2em);
    }
  }

  &__close {
    right: 10px;
    position: absolute;
    padding: 0.4rem 0.7rem;

    > svg {
      path {
        fill: gray;
      }
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    margin-top: 1.875rem;
  }

  &__menuItem {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 2.735rem;
    background-color: $white;

    > li {
      cursor: pointer;
      a {
        color: $dark-5;
        text-decoration: none;
      }
    }

    li + li {
      padding-top: 2.7rem;
    }
  }

  @media (min-width: 768px) {
    &__mobile {
      @media (min-width: 768px) {
        display: none;
      }
    }

    &__close {
      display: none;
    }

    &__logo {
      margin: unset;

      .BusinessLogo--solid .BusinessLogo__name {
        color: $white;
      }
    }

    &__main {
      top: unset;
      left: unset;
      right: unset;
      width: unset;
      bottom: unset;
      display: flex;
      padding: unset;
      position: unset;
      background-color: unset;
      justify-content: space-between;
    }

    &__menuItem,
    &__menuItem {
      margin-top: unset;
    }

    &__menuItem {
      display: flex;
      align-items: center;
      background-color: unset;

      li {
        a {
          color: $white;
        }
      }

      li + li {
        padding: unset;
        margin-left: 29.9px;
      }
    }
  }
}
