@import 'variables';

:root {
  --background-color: white;
  --text-theme-color: black;
}

.theme-blue {
  --background-color: #635bff;
  --background-color2: #f4f6fa;
  --background-color3: #554ede;
  --border-color1: #dcf2ea;

  --bg-600: #0a00c6;
  --bg-500: #352aff;
  --bg-400: #635bff;
  --bg-300: #b1adff;
  --bg-200: #d3d0ff;
  --bg-100: #e9e8ff;
  --bg-50: #f4f3ff;
}

.theme-green {
  --background-color: #47b881;
  --background-color2: #f4f6fa;
  --background-color3: #3ba772;

  --bg-600: #317159;
  --bg-500: #339a73;
  --bg-400: #52bd94;
  --bg-300: #a3e6cd;
  --bg-200: #dcf2ea;
  --bg-100: #eef8f4;
  --bg-50: #f5fbf8;
}

.theme-purple {
  --background-color: #6e62b6;
  --background-color2: #f4f6fa;
  --background-color3: #5e549e;

  --bg-600: #524988;
  --bg-500: #6e62b6;
  --bg-400: #897ae3;
  --bg-300: #b8afee;
  --bg-200: #d0caf4;
  --bg-100: #e7e4f9;
  --bg-50: #f8f7fd;
}

.theme-teal {
  --background-color: #10899e;
  --background-color2: #f4f6fa;
  --background-color3: #0b778a;

  --bg-600: #0f5156;
  --bg-500: #10899e;
  --bg-400: #25cbd6;
  --bg-300: #7ce0e6;
  --bg-200: #a8eaef;
  --bg-100: #d3f5f7;
  --bg-50: #f2fcfd;
}

.theme-pink {
  --background-color: #be449b;
  --background-color2: #f4f6fa;
  --background-color3: #8e3374;

  --bg-600: #8e3374;
  --bg-500: #be449b;
  --bg-400: #ed55c2;
  --bg-300: #f499da;
  --bg-200: #f8bbe7;
  --bg-100: #fbddf3;
  --bg-50: #fef5fb;
}

.theme-orange {
  --background-color: #b25e3a;
  --background-color2: #f4f6fa;
  --background-color3: #9f520a;

  --bg-600: #85462b;
  --bg-500: #b25e3a;
  --bg-400: #de7548;
  --bg-300: #ebac91;
  --bg-200: #f2c8b6;
  --bg-100: #f8e3da;
  --bg-50: #fdf7f4;
}

.theme-yellow {
  --background-color: #b25e3a;
  --background-color2: #f4f6fa;
  --background-color3: #845a0e;

  --bg-600: #66460d;
  --bg-500: #996a13;
  --bg-400: #ffb020;
  --bg-300: #ffd079;
  --bg-200: #ffdfa6;
  --bg-100: #ffefd2;
  --bg-50: #fffaf1;
}
