@import '../../colors';

.StatusIndicator {
  display: flex;
  align-items: center;

  &__dot {
    width: 6px;
    height: 6px;
    border-radius: 10px;
    margin-right: 10px;

    &--default {
      background: rgba($dark-4, 0.3);
    }

    &--success {
      background: $base-green;
    }

    &--warning {
      background: $base-orange;
    }

    &--danger {
      background: $base-red;
    }

    &--info {
      background: $base-blue;
    }
  }

  &__text {
    color: $dark-2;
    font-size: 0.75rem;
    text-transform: capitalize;
    font-family: 'Inter', sans-serif;
  }
}

.PasswordStrengthIndicator {
  width: 100%;
  height: 6px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px;

  & > div {
    border-radius: 5px;
    background: rgba($dark-4, 0.3);
  }

  &--weak {
    .Indicator--1 {
      background: $base-red;
    }
  }

  &--okay {
    .Indicator--1,
    .Indicator--2 {
      background: $base-orange;
    }
  }

  &--strong {
    & > div {
      background: $base-green;
    }
  }
}
