@import '_variables';

.CustomCheckbox {
  padding: 14px;
  border-radius: 16px;
  border: 1px solid $grey-5;
  display: flex;
  align-items: center;

  &--checked {
    border-color: var(--bg-400);
    background-color: var(--bg-50);

    .CustomCheckbox__icon {
      background-color: var(--bg-100);
      border: 4px solid var(--bg-200);
    }
  }

  &__icon {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1em;

    border-radius: 50%;
    background-color: $neutral-5;
    border: 4px solid $neutral-4;
  }

  label {
    color: $dark-5;
    font-weight: 500;
    flex-grow: 1;
    cursor: pointer;
  }

  &__input {
    margin-left: auto;
  }

  input {
    width: 16px;
    height: 16px;
    background-color: $white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid $grey-1;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;

    &:checked {
      border: 4px solid var(--bg-400);
    }
  }
}
