@import '../../../colors';

.Button {
  border: unset;
  font-size: 14px;
  border-radius: 5px;
  padding: 14px 15px;
  color: $white;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &--primary {
    background: $dark-purple;
  }

  &--secondary {
    background: $base-green;
  }

  &--outline {
    background: transparent;
    color: $dark-2;
    border: 1px solid rgba(16, 24, 64, 0.15);
  }

  &--text {
    background: transparent;
    color: $dark-5;
  }
}
