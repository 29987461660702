@import 'variables';
@import 'theme';

.Header {
  .NavBar {
    display: none;
  }

  .BusinessLogo {
    justify-content: center !important;
    padding: 2em;
  }

  @media screen and (max-width: $breakpoint-tablet) {
    &--desktop {
      display: none;
    }

    .NavBar {
      display: block;
      background-color: $neutral-8;
    }

    .BusinessLogo {
      padding: 0;

      &__name {
        color: $dark-5;
      }

      .Avatar {
        background-color: var(--bg-400);
        color: $white;
      }
    }

    .NavBar + .BusinessLogo {
      display: none !important;
    }

    .NavBar__mobile {
      padding-left: 1em;

      svg {
        path {
          fill: $dark-1;
        }
      }
    }
  }
}
