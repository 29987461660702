@import 'variables';

.ProgressBar {
  width: 100%;
  height: 6px;
  background-color: $neutral-4;
  position: relative;
  border-radius: $border-radius;

  &__indicator {
    position: absolute;
    top: 0;
    bottom: 0;
    border-radius: $border-radius;
    background-color: var(--bg-400);

    transition: width 0.7s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
}
