@import 'theme';

.ThemedButton {
  background-color: var(--bg-500);
  border-radius: 10px !important;
}

.ThemedLink {
  border: unset;
  font-size: 14px;
  padding: 14px 15px;
  color: $white;
  cursor: pointer;
  text-decoration: none;

  background-color: var(--bg-500);
  border-radius: 10px !important;
}
