@import '../../colors';
@import '@fontsource/inter';

.Heading {
  font-weight: 600;
  margin: 0;
  font-family: 'Inter', sans-serif;

  &--xxxs {
    color: $dark-1;
    font-size: 0.6875rem;
    text-transform: uppercase;
    line-height: 1rem;
    letter-spacing: 0.3px;
  }

  &--xxs {
    color: $dark-1;
    font-size: 0.75rem;
    line-height: 1rem;
  }

  &--xs {
    color: $dark-3;
    font-size: 0.75rem;
    line-height: 1rem;
  }

  &--s {
    color: $dark-3;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: -0.05px;
  }

  &--m {
    color: $dark-3;
    font-size: 1rem;
    line-height: 1.25rem;
    letter-spacing: -0.05px;
  }

  &--l {
    color: $dark-3;
    font-size: 1.25rem;
    line-height: 1.5rem;
    letter-spacing: -0.056px;
  }

  &--xl {
    color: $dark-3;
    font-size: 1.5rem;
    line-height: 1.75rem;
    letter-spacing: -0.06px;
  }

  &--xxl {
    color: $dark-3;
    font-size: 1.8125rem;
    line-height: 2rem;
    letter-spacing: -0.241px;
  }

  &--xxxl {
    color: $dark-3;
    font-size: 35px;
    line-height: 40px;
    letter-spacing: -0.24px;
  }
}

// Text styles

.Text {
  color: $dark-2;
  margin: 0;
  font-family: 'Inter', sans-serif;

  &--s {
    font-size: 0.75rem;
  }

  &--m {
    font-size: 0.875rem;
  }

  &--l {
    font-size: 1rem;
  }

  &--normal {
    font-weight: 400;
  }

  &--medium {
    font-weight: 500;
  }

  &--bold {
    font-weight: 600;
  }
}

// Paragraph Styles

.Paragraph {
  color: $dark-2;
  margin: 0;
  font-family: 'Inter', sans-serif;

  &--s {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }

  &--m {
    font-size: 0.875rem;
    line-height: 1.3125rem;
  }

  &--l {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  &--regular {
    font-weight: 300;
  }

  &--semi-bold {
    font-weight: 400;
  }

  &--bold {
    font-weight: 500;
  }
}
