@import '_variables.scss';

.Tabs {
  &__nav {
    padding: 0;
    margin: 0;
    display: flex;
    position: relative;
    justify-content: space-between;
    border-bottom: 2px solid $neutral-4;
  }

  &__title {
    color: $dark-5;
    list-style: none;
    padding: 1em;
    cursor: pointer;

    &--active {
      color: var(--bg-400);
    }
  }

  &__indicator {
    height: 2px;
    bottom: -2px;
    position: absolute;
    transition: left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:after {
      content: '';
      display: block;
      margin: 0 auto;
      width: 90%;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-bottom: 2px solid var(--bg-400);
      border-left: 2px solid transparent;
      border-right: 2px solid transparent;
    }
  }

  &__content {
    margin-top: 2em;
  }
}
