@import 'variables';

.CryptoFiat {
  position: relative;

  &__asset {
    color: var(--bg-400);
  }

  &__sell {
    font-weight: 600;
    line-height: 32px;
    font-size: $font-24;
  }

  .CustomSelect {
    min-width: 105px;

    &__indicator {
      padding: 2px;
    }
  }

  &__sendCash {
    margin: 0;

    .Field_label {
      display: none;
    }

    .TextField__container {
      border: none;
      padding: 0px;

      input[type='number']::-webkit-inner-spin-button,
      input[type='number']::-webkit-outer-spin-button {
        margin: 0;
        -webkit-appearance: none;
      }

      &:focus-within {
        border: none !important;
        box-shadow: none;
      }

      input {
        width: 100%;
        font-weight: 600;
        color: $neutral-9;
        font-size: $font-20;
        background-color: transparent;
      }
    }
  }

  &__arrowContainer {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: var(--bg-500);

    left: 0;
    right: 0;
    position: absolute;
    margin: -16px auto;
  }

  &__sendFunds {
    border-radius: $font-16;
    padding: $font-18 $font-15;
    background-color: $neutral-8;
    border: 1px solid $grey-8;
    margin: 8px 0;

    > p {
      font-size: $font-14;
    }
  }

  &__assetValue {
    padding: 0;
    font-weight: 600;
  }
}
