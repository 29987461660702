.SkeletonLoader {
  opacity: 0.7;
  animation: loading 1s linear infinite alternate;
}

@keyframes loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
