$light-red-1: #fef6f6;
$light-red-2: #fae2e2;
$base-red: #ec4c47;
$dark-red: #bf0e08;
$dark-red-2: #d14343;

$light-orange-1: #fdf8f3;
$light-orange-2: #fae3cd;
$base-orange: #d9822b;
$dark-orange: #95591e;

$light-yellow-1: #fef8e7;
$light-yellow-2: #fbe6a2;
$base-yellow: #f7d154;
$dark-yellow: #7e6514;

$light-green-1: #f1faf5;
$light-green-2: #d4eee2;
$base-green: #47b881;
$dark-green: #00783e;

$light-teal-1: #f1fbfc;
$light-teal-2: #d2eef3;
$base-teal: #14b5d0;
$dark-teal: #007489;

$light-blue-1: #f6f9fd;
$light-blue-2: #dbeaf7;
$base-blue: #1070ca;
$dark-blue: #084b8a;

$light-purple-1: #f8f7fc;
$light-purple-2: #eae7f8;
$base-purple: #7540f6;
$dark-purple: #3608aa;

$white: #ffffff;
$neutral-1: #f5f8f9;
$neutral-2: #edf0f2;
$neutral-3: #e4e7eb;

$dark-1: #66788a;
$dark-2: #425a70;
$dark-3: #234361;
$dark-4: #435a6f;
$dark-5: #696f8c;

$light-1: #d7dae5;
